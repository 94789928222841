import { RaceTypesMap } from '@apollo/core/src/constants';
import moment from 'moment';
import React from 'react';
import { useSettings } from '@apollo/core';
import { RaceRunnerImage } from './RaceRunnerImage/RaceRunnerImage';
import Label from '../../../shared/components/I18n/Label';

const RaceRunner = ({
  runner,
  activeRace,
  runnerStatic,
  showFinish = true,
  showRunnerImage,
  hideJockey = false,
  marketOutcome,
}) => {
  const { debugMode } = useSettings();
  return (
    <div className='event__info'>
      <div className='event-participants race'>
        {showRunnerImage && (
          <RaceRunnerImage
            runner={runner}
            showFinish={showFinish}
            masked={activeRace?.type !== 'DOG'}
          />
        )}
        <div className={hideJockey ? 'flex-center flex-column runner-info' : 'runner-info'}>
          <div className='event-participants__participant race'>
            <div>
              {runner?.number && (
                <>
                  <span className='event-participants__participant-index'>
                    {runner.number}
                    .
                  </span>
                  <span className='event-participants__participant-name race bold'>
                    {runner.name}
                  </span>
                  {' '}
                  {activeRace?.type !== RaceTypesMap.TROT.id && runner?.barrier ? (
                    <span className='runner-barrier'>
                      (
                      {runner.barrier}
                      )
                    </span>
                  ) : null}
                </>
              )}
              {marketOutcome && (
              <div className=''>
                {marketOutcome?.name}
                {' '}
              </div>
              )}
            </div>
          </div>
          {runner.scratched ? (
            <div className='event-participants__participant--scratched'>
              <Label message='Scratched' />
              {runner.scratchTime
                ? ` @ ${moment(runner.scratchTime).format('DD MMM, HH:mm')}`
                : null}
            </div>
          ) : (
            <>
              {' '}
              {hideJockey ? null : (
                <div className='event-participants__participant-info'>
                  {activeRace?.type !== RaceTypesMap.DOG.id && (
                    <div className='event-participants__participant-info_flex'>
                      <div className='event-participants__participant-info_item'>
                        <span className='event-participants__participant-info_itemLabel'>
                          {activeRace?.type === RaceTypesMap.HORSE.id ? 'J' : 'D'}
                          :
                        </span>
                        <span
                          className='event-participants__participant-info_itemValue'
                          title={runner.jockeyFullName}
                        >
                          {runner.jockeyFullName}
                        </span>
                      </div>
                      {activeRace?.type !== RaceTypesMap.TROT.id && (
                        <div className='event-participants__participant-info_item'>
                          {/* <span className='event-participants__participant-info_itemLabel'> */}
                          {/*  W: */}
                          {/* </span> */}
                          <span className='event-participants__participant-info_itemValue'>
                            {runner.weight}
                            {' '}
                            Kg
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  <div className='event-participants__participant-info_flex'>
                    {runner.trainerFullName && (
                      <div className='event-participants__participant-info_flex-wrap'>
                        <span className='event-participants__participant-info_itemLabel'>T:</span>
                        <span
                          className='event-participants__participant-info_itemValue'
                          title={runner.trainerFullName}
                        >
                          {runner.trainerFullName.length > 18
                            ? runner.trainerFullName.slice(0, 18)
                            : runner.trainerFullName}
                        </span>
                      </div>
                    )}
                    {runnerStatic && runnerStatic.staticInfo?.last5Starts && (
                      <div className='event-participants__participant-info_flex-wrap'>
                        <span className='event-participants__participant-info_itemLabel'>F:</span>
                        <span
                          className='event-participants__participant-info_itemValue'
                          title={runnerStatic.staticInfo.last5Starts}
                        >
                          {runnerStatic.staticInfo.last5Starts}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RaceRunner;
